import React from "react";
import { observer } from "mobx-react-lite";
import {
	Text,
	Flex,
	FlexProps,
} from "@chakra-ui/react";

interface IProps extends FlexProps {
	text: string;
	fontSizeText?: string;
}

export const InfoText: React.FC<IProps> = observer((props) => {
	const {
		text,
		fontSizeText,
		...rest
	} = props;

	return (
		<Flex
			width="100%"
			justifyContent="space-between"
			{...rest}
		>
			<Text
				fontSize={fontSizeText}
				color="gray.500"
			>
				{text}</Text>
		</Flex>
	);
});
