import React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	Divider,
	Flex,
	HStack,
	Image,
	Text,
	VStack,
	Center,
	Link,
} from "@chakra-ui/react";
import { useRouter } from "next/router";

import { categorySection } from "./categorySection";
import { LinkButton } from "~/components";
import { strings } from "~/resources";

interface IProps {
	noDivider?: boolean;
}

export const Footer: React.FC<IProps> = observer((props) => {
	const fontSizeBreakPoint = { md: "md", lg: "18px" };
	const footerStrings = strings.components.Footer;
	const router = useRouter();

	const onClickGTagEvent = (eventName: string) => {
		if (typeof gtag !== "undefined") {
			gtag("event", eventName, {
				event_category: "Site Forms",
			});
		}
	};

	return (
		<>
			{!props.noDivider && (
				<Center>
					<Divider
						mr="5em"
						ml="5em"
						borderWidth="1px"
						borderColor="#C4C4C4"
					/>
				</Center>
			)}
			<Flex
				py={20}
				px={{ base: 5, sm: 50, lg: 70 }}
				justifyContent={{ base: "center", md: "space-between" }}
				alignItems="flex-start"
			>

				<VStack
					width={{ base: "100%", md: "60%" }}
					alignItems={{ base: "center", md: "flex-start" }}
					spacing={3}
					mr={{ base: 0, md: 20 }}
					px={{ base: 0, md: 18 }}
				>
					<Image
						alt="ConnectVet-logo"
						src="/logo.png"
						h={{ base: "12rem", md: "10rem", lg: "12rem" }}
						mb={5}
					/>
					<Text
						color="gray.600"
						fontSize="20px"
						textAlign={{ base: "center", md: "start" }}
					>
						{footerStrings.company}
					</Text>
					<Text
						color="gray.600"
						fontSize="20px"
						textAlign={{ base: "center", md: "start" }}
					>
						{footerStrings.cnpj}
					</Text>
					<Divider
						my={2}
						maxWidth="45px"
						borderColor="secondary.500"
						borderWidth={4}
					/>
					<Text
						fontSize="20px"
						textColor="gray.600"
						textAlign={{ base: "center", md: "start" }}
					>
						{footerStrings.email}
					</Text>
					<HStack
						w="100%"
						flexDirection={{ base: "column", md: "row"}}
						justifyContent={{ base: "center", md: "start" }}
					>
						<Text
							textColor="gray.600"
							textAlign={{ base: "center", md: "start" }}
							fontSize="20px"
						>
							{footerStrings.phoneNumbers.phone}
						</Text>
						<Box display={{ base: "none", md: "flex"}} height="20px">
							<Divider
								orientation="vertical"
								borderRightWidth="1px"
								borderColor="secondary.500"
							/>
						</Box>
						<Text
							textColor="gray.600"
							textAlign={{ base: "center", md: "start" }}
							fontSize="20px"
						>
							{footerStrings.phoneNumbers.secondPhone}
						</Text>
						<Box display={{ base: "none", md: "flex"}} height="20px">
							<Divider
								orientation="vertical"
								borderRightWidth="1px"
								borderColor="secondary.500"
							/>
						</Box>
						<Text
							textColor="gray.600"
							textAlign={{ base: "center", md: "start" }}
							fontSize="20px"
						>
							{footerStrings.phoneNumbers.thirdPhone}
						</Text>
					</HStack>
					<HStack mt={2} spacing={4}>
						<Link href={footerStrings.socialLinks.instagramLink}>
							<Image
								alt="instagram-logo"
								src="/instagram.svg"
								cursor="pointer"
								h="35px"
							/>
						</Link>s
						<Link href={footerStrings.socialLinks.facebookLink}>
							<Image
								alt="facebook-logo"
								src="/facebook.svg"
								cursor="pointer"
								h="35px" />
						</Link>

					</HStack>
					<Text
						textColor="gray.600"
						textAlign={{ base: "center", md: "start" }}
						fontSize="20px"
					>
						{footerStrings.downloadSection}
					</Text>
					<Flex
						flexDirection={{ base: "column", md: "row" }}
						justifyContent="space-between"
					>
						<Image
							alt="google-play-logo"
							src="/getOnGooglePlay.svg"
							cursor="pointer"
							h="55px"
						/>
						<Image
							alt="apple-store-logo"
							src="/getOnAppleStore.svg"
							cursor="pointer"
							h="55px"
							ml={{ base: 0, md: 5 }}
							mt={{ base: 5, md: 0 }}
						/>
					</Flex>
				</VStack>
				<Flex>
					<Box
						display={{ base: "none", md: "flex" }}
						flexDirection="column"
					>
						{categorySection.map((navigation) => (
							<LinkButton
								key={navigation.name}
								routeLink={navigation.link}
							>
								<Text
									cursor="pointer"
									color="primary.500"
									fontSize={fontSizeBreakPoint}
									mr="5"
									fontWeight={navigation.link.href === router.asPath ? "bold" : "normal"}
									textAlign="end"
									onClick={() => onClickGTagEvent(`${navigation.name} Clicked`)}
								>
									{navigation.name}
								</Text>
							</LinkButton>
						))}
					</Box>
				</Flex>
			</Flex>
		</>
	);
});

