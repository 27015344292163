import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Center, Text } from "@chakra-ui/react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

interface IProps {
	previousPage: () => void;
	nextPage: () => void;
	page: number;
	hasNextPage: boolean;
}

export const PagesIndicator: React.FC<IProps> = observer((props) => {
	const {
		previousPage,
		nextPage,
		page,
		hasNextPage,
	} = props;

	return (
		<Center justifyContent="right" maxW="1000px" m="auto">
			<Button
				variant="unstyled"
				disabled={page === 0}
				w={1}
				onClick={previousPage}
			>
				<Center >
					<Text fontSize={24}>
						<GrFormPrevious />
					</Text>
				</Center>
			</Button>
			<Center>
				<Text fontSize={24}>
					{page + 1}
				</Text>
			</Center>
			<Button
				variant="unstyled"
				w={1}
				disabled={!hasNextPage}
				onClick={nextPage}
			>
				<Center>
					<Text fontSize={24}>
						<GrFormNext />
					</Text>
				</Center>
			</Button>
		</Center>
	);
});
