import React from "react";
import { Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";

import { ResponsiveGridSection } from "../ResponsiveGridSection";
import strings from "~/resources/strings";
import { CardClient } from "..";

interface IProps {
	clients: any[];
}

export const SectionClients: React.FC<IProps> = observer((props) => {
	const { clients } = props;
	const componentStrings = strings.components.sectionClients;

	return (
		<Box bgColor="white">
			<ResponsiveGridSection
				title={componentStrings.title}
				items={clients}
				renderItem={(client, index) => (
					<CardClient
						minW={{ base: 240, md: 300 }}
						key={index}
						name={client.name}
						pet={client.pet}
						image={client.image}
						rating={client.rating}
						description={client.description}
						onClick={() => {}}
						mr={5}
						mb={1}
					/>
				)}
			/>
		</Box >
	);
});
