import strings from "~/resources/strings";
import Routes from "~/resources/routes";

const mainRoutesStrings = strings.routeNames;

export const categorySection = [
	{
		name: mainRoutesStrings.home,
		link: Routes.index(),
	},
	{
		name: mainRoutesStrings.clinics,
		link: Routes.clinics(),
	},
	{
		name: mainRoutesStrings.ourTeam,
		link: Routes.ourTeams(),
	},
	{
		name: mainRoutesStrings.androidDownload,
		link: Routes.androidDownload(),
	},
	{
		name: mainRoutesStrings.iosDownload,
		link: Routes.iosDownload(),
	},
	{
		name: mainRoutesStrings.faq,
		link: Routes.faq(),
	},
	{
		name: mainRoutesStrings.blog,
		link: Routes.blog(),
	},
];
