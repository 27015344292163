import React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	BoxProps,
	Textarea,
	TextareaProps,
} from "@chakra-ui/react";
import { Label } from "..";
import spacing from "../../themes/spacing";

export interface IProps extends Omit<TextareaProps, "value">{
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	labelText?: string;
	value?: string | null;
	helper?: string;
	boxProps?: BoxProps;
	mask?: string;
	inputRef?: React.RefObject<HTMLInputElement>;
}

export const TextareaInput: React.FC<IProps> = observer((props) => {
	const {
		onChange,
		labelText,
		helper,
		boxProps,
		value,
		...rest
	} = props;

	return(
		<Box {...boxProps}>
			<Label marginBottom={spacing.s1} color="white" helper={helper}>{labelText}</Label>
			<Textarea
				minH={"200px"}
				borderWidth={spacing.s2}
				bg="#FFFFFF"
				borderRadius={spacing.s10}
				_hover={{
					borderWidth: spacing.s2,
					borderColor: "primary.700",
				}}
				onChange={onChange}
				value={value}
				defaultValue={value || undefined}
				{...rest}
			/>
		</Box>
	);
});
