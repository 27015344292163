import React from "react";
import {
	Box,
	Button,
	CenterProps,
	Heading,
	Text,
	Center,
	Flex,
} from "@chakra-ui/react";
import { TextInput } from "..";
import spacing from "~/themes/spacing";
import {
	api,
	strings,
} from "~/resources";
import { TextareaInput } from "../TextareaInput";

export type ContactField = "name" | "email" | "phone" | "message";

interface IProps extends CenterProps {
	contact: api.NewContact;
	loading: boolean;
	onHandleChange: (field: ContactField, value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onSubmit: () => void;
}

const componentStrings = strings.components.contactForm;

export const ContactForm: React.FC<IProps> = (props) => {

	const {
		contact,
		loading,
		onHandleChange,
		onSubmit,
	} = props;

	return (
		<Flex
			backgroundImage="pet.png"
			backgroundPosition="center"
			backgroundSize="cover"
			id="contact"
		>
			<Flex
				background="rgba(29, 70, 125, 0.89)"
				width="100%"
				alignItems="center"
				px={5}
				py={8}
				flexDirection="column"
			>

				<Center flexDirection="column" w={{ base: "100%", lg: "40%" }}>
					<Heading
						fontWeight="bold"
						color="primary.600"
						fontSize="60px"
					>
						{componentStrings.title}
					</Heading>
					<Text textAlign="center" fontSize="30px" color="white" py="20px">
						{componentStrings.tip}
					</Text>
					<Box width="100%">
						<TextInput
							labelText={componentStrings.name}
							placeholder={componentStrings.name}
							value={contact.name}
							onChange={(e) => onHandleChange("name", e)}
						/>
						<TextInput
							boxProps={{ paddingY: spacing.s4 }}
							labelText={componentStrings.email}
							placeholder={componentStrings.email}
							value={contact.email}
							onChange={(e) => onHandleChange("email", e)}
						/>
						<TextInput
							mask={"(99) 99999-9999"}
							labelText={componentStrings.phone}
							placeholder={componentStrings.phone}
							value={contact.phone}
							onChange={(e) => onHandleChange("phone", e)}
						/>
						<TextareaInput
							boxProps={{ paddingY: spacing.s4 }}
							labelText={componentStrings.message}
							placeholder={componentStrings.message}
							value={contact.message}
							onChange={(e) => onHandleChange("message", e)}
						/>
					</Box>
					<Button
						mt={spacing.s8}
						onClick={onSubmit}
						disabled={loading}
						bg="#D37B2D"
						isLoading={loading}
					>
						{componentStrings.send}
					</Button>
				</Center>
			</Flex>

		</Flex>
	);
};
