
import React from "react";
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Icon,
} from "@chakra-ui/react";
import { AiOutlineMenu } from "react-icons/ai";
import { LinkButton } from "../../../components";
import mainRoutes from "../../../resources/navigationLinks";

export const MenuOptions = () => (
	<Menu>
		<MenuButton>
			<Icon
				display={{ base: "block", lg: "none" }}
				cursor="pointer"
				borderWidth={1}
				borderColor="primary.500"
				p={2}
				borderRadius="base"
				color="primary.500"
				w={10}
				h={10}
				as={AiOutlineMenu}
			/>
		</MenuButton>
		<MenuList>
			{mainRoutes.map((navigation) => (
				<LinkButton key={navigation.name} routeLink={navigation.link}>
					<MenuItem color="primary.500">
						{navigation.name}
					</MenuItem>
				</LinkButton>
			))}
		</MenuList>
	</Menu>
);
