import React from "react";
import { Box, Image } from "@chakra-ui/react";

import { NavbarLinks } from "./NavbarLinks";
import { useRouter } from "next/router";

export const Navbar: React.FC = () => {
	const router = useRouter();

	return (
		<Box
			maxW={1100}
			mx="auto"
			px={{base: "20px" , lg: "10px"}}
			py="15px"
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			bg="white"
		>
			<Image
				alt="ConnectVet-logo"
				src="/logo.png"
				h={{base:"65px", sm: "75px"}}
				w={{base:"90px", sm: "83px"}}
			/>
			<NavbarLinks currentPage={router.asPath} />
		</Box>
	);
};
