import React from "react";
import {
	Flex,
	FlexProps,
	Text,
	Image,
} from "@chakra-ui/react";
import { LinkButton } from "..";
import { ILink } from "~/resources/routes";

interface IProps extends FlexProps {
	src: string | null;
	name: string;
	description: string | JSX.Element;
	descriptionBgGray?: boolean;
	link?: ILink;
}

export const InfoCard: React.FC<IProps> = (props) => {
	const {
		src,
		name,
		description,
		link,
		descriptionBgGray,
		onClick,
		...rest } = props;

	return (
		link ? (
			<LinkButton routeLink={link}>
				<Flex
					flexDirection="column"
					onClick={onClick}
					borderRadius={5}
					overflow="hidden"
					{...rest}
				>
					<Image
						alt="clinic-image"
						width="100%"
						height={"300px"}
						src={src || "/doctor.png"}
					/>
					<Flex
						flexDirection="column"
						bgColor={`${descriptionBgGray ? "gray.100" : "white"}`}
						pt={3}
						pb={6}
						flex={1}
						px={4}
					>
						<Text
							color="primary.600"
							fontSize="18px"
							fontWeight="bold"
						>
							{name}
						</Text>
						<Text
							color="gray.500"
							fontSize="sm"
							mt={-1}
						>
							{description}
						</Text>
					</Flex>
				</Flex>
			</LinkButton>
		) : (
			<Flex
				flexDirection="column"
				onClick={onClick}
				minH={"400px"}
				borderRadius={5}
				overflow="hidden"
				{...rest}
			>
				<Image
					alt="clinic-image"
					width="100%"
					height={300}
					objectFit="cover"
					objectPosition="center"
					src={src || "/doctor.png"}
				/>
				<Flex
					flexDirection="column"
					bgColor={`${descriptionBgGray ? "gray.100" : "white"}`}
					w="100%"
					flex={1}
					pt={3}
					pb={6}
					px={4}
				>
					<Text color="primary.600" fontSize="18px" fontWeight="bold">
						{name}
					</Text>
					<Text color="gray.500" fontSize="sm" mt={-1} >
						{description}
					</Text>
				</Flex>
			</Flex>
		)
	);
};
