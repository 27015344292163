import React from "react";
import { observer } from "mobx-react-lite";
import { Grid } from "@chakra-ui/react";
import { ContentSection } from "../ContentSection";
import { EmptyList } from "..";

interface IProps<DataType> {
	title: string;
	items: DataType[];
	renderItem: (item: DataType, index?: number) => JSX.Element;
}

export const ResponsiveGridSection = observer(<DataType,>(props: IProps<DataType>) => {
	const { title, items, renderItem } = props;

	return (
		<ContentSection
			title={title}
			py={{ base: 5, lg: 16 }}
		>
			{items.length
				? (
					<Grid
						templateColumns={{
							base: "repeat(1, 1fr)",
							md: "repeat(2, 1fr)",
							lg: "repeat(3, 1fr)",
						}}
						gap={5}
						mt={5}
						mx="auto"
					>
						{items.map((item, index) => renderItem(item, index))}
					</Grid>
				)
				: <EmptyList />
			}
		</ContentSection>
	);
});
