import React from "react";
import { Image, Flex, FlexProps, Text, Box } from "@chakra-ui/react";
import { RatingStar } from "rating-star";


interface IProps extends FlexProps {
	image: string | null;
	name: string;
	pet: string;
	rating: number;
	description: string | JSX.Element;
	onClick?: () => void;
}

export const CardClient: React.FC<IProps> = (props) => {
	const {
		image,
		name,
		pet,
		rating,
		description,
		onClick,
		...rest
	} = props;

	return (
		<Flex
			flexDirection="column"
			onClick={onClick}
			minH={401}
			borderRadius={5}
			pt={"40px"}
			{...rest}
		>
			<Flex
				flexDirection="column"
				w="100%"
				pt={2}
				pb={6}
				px={4}
				flex={1}
				position="relative"
				borderWidth={1}
				borderColor="#1D467D"
			>
				<Image
					top="-30px"
					rounded="full"
					width={90}
					height={90}
					objectFit="cover"
					src={image || "/doctor.png"}
					alt="avatar-image"
					position="absolute"
				/>
				<Box
					pl={{ base: 0, md:"98px" }}
					pb={2}
					mt={{ base: "80px", md:0 }}
				>
					<Text color="primary.600" fontSize="16px" fontWeight="bold">
						{name}
					</Text>
					<Text color="primary.600" fontSize="16px" fontWeight="bold">
						{pet}
					</Text>
					<RatingStar
						id="ratingProductAverage"
						rating={rating}
						size={16}
						numberOfStar={5}
						colors={{ mask: "#FFBB00" }}
						noBorder
					/>
				</Box>
				<Text flex={1} color="gray.500" fontSize="sm" >
					{description}
				</Text>
			</Flex>
		</Flex>
	);
};
