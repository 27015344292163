import React from "react";
import {
	Flex,
	TextProps,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";

export interface IProps extends TextProps{
	text?: string;
}
export const EmptyList: React.FC<IProps> = (props) => {
	const { text, ...rest} = props;
	return (
		<Flex justifyContent="center">
			<Text {...rest} color="gray.500" fontSize="xl" textAlign="center">{text || strings.components.emptyList.title}</Text>
		</Flex>
	);
};
