import React from "react";
import { Box } from "@chakra-ui/react";
import { NavbarShadow } from "../../components";
import { SiteHead } from "../SiteHead";
import { Navbar } from "../Navbar";
import { Footer } from "../../layout/Footer";

interface IProps {
	noDivider?: boolean;
}

export const MainLayout: React.FC<IProps> = (props) => {
	const { children, noDivider } = props;

	return (
		<Box minH="100vh">
			<Navbar />
			<NavbarShadow />
			<SiteHead />
			{children}
			<Footer noDivider={noDivider} />
		</Box>
	);
};
