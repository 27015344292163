import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "@chakra-ui/react";
import { marginSpaceForContent } from "~/resources/MarginSpaceForContent";

export const PageContainer: React.FC = observer((props) => {
	const { children } = props;

	return (
		<Box my={{base: "0.5rem", md: "3rem"}} mx={marginSpaceForContent}>
			{children}
		</Box>
	);
});

