import React from "react";
import {
	Box,
	Button,
	HStack,
	Text,
	Image,
	VStack,
	Flex,
	useBreakpointValue,
} from "@chakra-ui/react";
import NextImage from "next/image";
import { useRouter } from "next/router";
import { api, format, strings } from "~/resources";
import { InfoText } from "..";
import ImagePlaceHolder from "../../public/person1.svg";

interface IProps {
	adminUserToSite: api.AdminUserToSite;
}

export const ClinicContainer: React.FC<IProps> = (props) => {
	const pageStrings = strings.screen.clinics;
	const variant = useBreakpointValue({ base: "60%", md: "55%", lg: 40 });
	const router = useRouter();

	const goToContact = () => {
		router.push("/#contact");
	};

	const { adminUserToSite } = props;

	const clinic = adminUserToSite.clinic;

	return (
		<Flex
			flexDir="column"
			alignItems="center"
			w="100%"
		>
			{
				clinic && (
					<>
						<Flex
							flexDir="column"
							w="100%"
						>
							<NextImage
								width="100%"
								height={variant}
								quality={100}
								layout="responsive"
								alt="clinic-image"
								src={clinic.image ? clinic.image.url : ImagePlaceHolder}
							/>
							<VStack
								alignItems="flex-start"
								spacing={7}
								mx={{ base: 5, lg: "18%" }}
								mt={6}
							>
								<Text
									color="secondary.700"
									fontSize="40px"
									fontWeight="bold"
								>
									{adminUserToSite.name}
								</Text>
								<Box>
									<Text
										color="secondary.700"
										fontSize="30px"
										fontWeight="bold"
									>
										{pageStrings.address}
									</Text>
									<InfoText fontSizeText="md"
										text={format.address(clinic.address)}
									/>
								</Box>
								<Box>
									<Text
										color="secondary.700"
										fontSize="30px"
										fontWeight="bold"
									>
										{pageStrings.services}
									</Text>
									<InfoText fontSizeText="md" text={clinic.services} />
								</Box>
								<Box w="90%">
									<Text
										color="secondary.700"
										fontSize="30px"
										fontWeight="bold"
										mb={4}
									>
										{pageStrings.contact}
									</Text>
									<VStack alignItems="flex-start" spacing={4}>
										<HStack
											spacing={4}
										>
											<Image
												alt="phone-icon"
												src="/phone.png"
											/>
											<Text
												fontSize="md"
												color="gray.500"
												maxW="100%"
											>
												{clinic.phone}
											</Text>
										</HStack>
										<HStack
											spacing={4}
											maxW={{
												base: "90%",
											}}
										>
											<Image
												alt="phone-icon"
												src="/email.png"
											/>
											<Text
												fontSize="md"
												color="gray.500"
												maxW="100%"
											>
												{adminUserToSite.email}
											</Text>
										</HStack>
									</VStack>
								</Box>
							</VStack>
						</Flex>
					</>
				)
			}
			<Button
				mt={20}
				fontSize="26px"
				rounded="xl"
				maxW={350}
				py={7}
				mb="60px"
				onClick={goToContact}
			>
				{pageStrings.buttonLabel}
			</Button>
		</Flex>
	);
};
