import React from "react";
import {
	Flex,
	CenterProps,
	Heading,
	Button,
	useBreakpointValue,
} from "@chakra-ui/react";
import { InfoText } from "~/components";
import spacing from "~/themes/spacing";
import { strings } from "~/resources";
import { useRouter } from "next/router";
import NextImage from "next/image";

interface IProps extends CenterProps {
	srcImage: string;
	title: string;
	subTitle: string;
	hideButton?: boolean;
	description: string;
}

const componentStrings = strings.components.initialSection;

export const IntroductionSection: React.FC<IProps> = (props) => {

	const router = useRouter();

	const goToContact = () => {
		router.push("/#contact");
	};

	const variantWidth = useBreakpointValue({base: 1000, md: 400,lg: 700});


	const {
		title, description, srcImage,
		hideButton, subTitle,
		...rest
	} = props;

	return (
		<Flex
			width="100%"
			justifyContent="space-between"
			flexWrap={{ base: "wrap", lg: "initial" }}
			flexDirection={{ base: "column-reverse", lg: "row" }}
			bg="gray.100"
			{...rest}
		>
			<Flex
				flexDirection="column"
				w={{base: "100%", md: "95%", lg: "40%"}}
				mx={{base: 0, lg: 8}}
				ml={{ base: 0, md: 12, lg: "80px"}}
				justifyContent="center"
				pt={{ base: "initial", lg: "initial" }}
			>
				<Heading
					fontSize={{base: "40px", lg: "60px"}}
					fontWeight="bold"
					color="secondary.700"
					mx={{base: 5, lg: 0}}
					mt={{ base: spacing.s8, lg: 8 }}
				>
					{title}
				</Heading>
				<Heading
					fontSize={{base: "40px", lg: "60px"}}
					fontWeight="bold"
					color="secondary.700"
					mx={{base: 5, lg: 0}}
					mb={6}
				>
					{subTitle}
				</Heading>
				<Flex
					flexDirection="column"
					justifyContent={{base: "center", lg: "flex-start"}}
					alignItems={{base: "center", lg: "flex-start"}}
					fontSize="20px"
					mb={{base: 5, lg: 8}}
					mx={{base: 5, lg: 0}}
				>
					<InfoText
						textAlign="justify"
						fontSizeText="24px"
						text={description}
					/>
					{!hideButton && (
						<Button
							height={{ base: "50px", lg: "60px"}}
							mt={8}
							backgroundColor="primary.500"
							fontSize={{base: "24px", lg: "35px"}}
							onClick={() => goToContact()}
						>
							{componentStrings.buttonText}
						</Button>
					)}
				</Flex>
			</Flex>
			<NextImage
				width={variantWidth}
				height={750}
				layout="intrinsic"
				alt="clinic-image"
				src={srcImage}
			/>
		</Flex>
	);
};
