import React from "react";
import { Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";

import { InfoCard } from "../InfoCard";
import { ResponsiveGridSection } from "../ResponsiveGridSection";
import strings from "~/resources/strings";
import { api } from "~/resources";
import Routes from "~/resources/routes";
interface IProps {
	clinics: api.AdminUserToSite[];
}

export const SectionAvailableClinics: React.FC<IProps> = observer((props) => {
	const { clinics } = props;
	const componentStrings = strings.components.sectionAvailableClinics;
	const onGoToClinic = () => { };

	return (
		<Box
			id="clinicSection"
			bgColor="white"
		>
			<ResponsiveGridSection
				title={componentStrings.title}
				items={clinics}
				renderItem={(clinic, index) => (
					<InfoCard
						minW={{ base: 240, md: 300 }}
						key={index}
						minH={{ base: 280, md: 320}}
						name={clinic.name}
						src={clinic.clinic.image.url}
						descriptionBgGray
						description={clinic.clinic.services}
						onClick={onGoToClinic}
						link={Routes.clinic(clinic.id)}
						mr={5}
						mb={1}
						mt={{
							base: 10,
							xl: 0,
						}}
					/>
				)}
			/>
		</Box >
	);
});
