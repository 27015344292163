// MARK: Implementation
export interface ILink {
	href: string;
	as: string;
}

interface IRouteType {
	[key: string]: ((...x: any) => ILink) | ILink | IRouteType;
}

const getRoutes = <T extends IRouteType>(value: T): T => value;

const Routes = getRoutes({
	index: (params?: string | null) => ({
		href: "/",
		as: `/${params || ""}`,
	}),
	clinics: () => ({
		href: "/#clinicSection",
		as: "",
	}),
	clinic: (params: string | null) => ({
		href: `/clinics/${params}`,
		as: "",
	}),
	androidDownload: () => ({
		href: "/androidDownload/",
		as: "",
	}),
	faq: () => ({
		href: "/faq/",
		as: "",
	}),
	iosDownload: () => ({
		href: "/iosDownload/",
		as: "",
	}),
	blog: (params?: string | null) => ({
		href: "/blog/",
		as: `/blog/${params || ""}`,
	}),
	post: (params: string) => ({
		href: `/blog/${[params]}`,
		as: `/blog/${params || ""}`,
	}),
	ourTeams: (params?: string | null) => ({
		href: "/ourTeams/",
		as: `/ourTeams/${params || ""}`,
	}),
});

export default Routes;
