import Routes, { ILink } from "./routes";
import strings from "./strings";

interface INavigationLinks {
	name: string;
	link: ILink;
}

const mainRoutesStrings = strings.routeNames;

const navigationLinks: INavigationLinks[] = [
	{
		name: mainRoutesStrings.home,
		link: Routes.index(),
	},
	{
		name: mainRoutesStrings.clinics,
		link: Routes.clinics(),
	},
	{
		name: mainRoutesStrings.ourTeam,
		link: Routes.ourTeams(),
	},
	{
		name: mainRoutesStrings.androidDownload,
		link: Routes.androidDownload(),
	},
	{
		name: mainRoutesStrings.iosDownload,
		link: Routes.iosDownload(),
	},
	{
		name: mainRoutesStrings.faq,
		link: Routes.faq(),
	},
	{
		name: mainRoutesStrings.blog,
		link: Routes.blog(),
	},
];

export default navigationLinks;
