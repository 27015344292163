import React from "react";
import {
	Box,
	Image,
	Divider,
	Text,
	Center,
	Flex,
	chakra,
} from "@chakra-ui/react";
import strings from "../../resources/strings";
import Routes from "../../resources/routes";
import { LinkButton } from "../../components";

interface IProps {
	imageSrc: string;
	title: string;
	description: string;
	id: string;
}

export const CardPost: React.FC<IProps> = (props) => {
	const {
		description,
		imageSrc,
		title,
		id,
	} = props;

	return (
		<LinkButton routeLink={Routes.post(id)} >
			<Box bg="white" borderBottomRadius="md">
				<Center flexDir="column">
					<Box>
						<Image
							h={{base:328, md: 428}}
							w={{base:388, md: 493}}
							src={imageSrc}
							alt="author-image"
						/>
					</Box>
					<Flex
						borderBottomRadius="2xl"
						p={6}
						flexDirection="column"
						bg="gray.100"
						h={278}
						w={{base:388, md: 493}}
						justifyContent="space-between"
					>
						<Box h="100%">
							<Text
								noOfLines={1}
								color="primary.500"
								fontWeight="bold"
								fontSize="30px"
							>
								{title}
							</Text>
							<Text
								fontWeight="light"
								textOverflow="ellipsis"
								noOfLines={{ base: 2, lg: 3 }}
								fontSize="24px"
								mt={1}
							>
								<chakra.div dangerouslySetInnerHTML={{ __html: description }} />
							</Text>
						</Box>
						<Center flexDir="column">
							<Divider
								w="70%"
								my={4}
								borderColor="gray.500"
							/>
							<Text cursor="pointer" fontSize="25px" color="gray.500">
								{strings.components.cardPost.readMore}
							</Text>
						</Center>
					</Flex>
				</Center>
			</Box>
		</LinkButton>
	);
};
