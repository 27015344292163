import React from "react";
import { Heading, Box, HeadingProps, BoxProps } from "@chakra-ui/react";

import { marginSpaceForContent } from "~/resources/MarginSpaceForContent";

interface IProps extends BoxProps {
	title?: string;
	headingProps?: HeadingProps;
}

export const ContentSection: React.FC<IProps> = (props) => {
	const { title, headingProps, children, ...rest } = props;

	return (
		<Box mx={marginSpaceForContent} {...rest}>
			<Heading
				color="secondary.700"
				textAlign="start"
				fontSize={{ base: "lg", sm: "2xl", lg: "40px" }}
				{...headingProps}
			>
				{title}
			</Heading>
			{children}
		</Box>
	);
};
