import React from "react";
import {
	Box,
	Text,
	Flex,
} from "@chakra-ui/react";
import { LinkButton } from "~/components";
import { MenuOptions } from "../MenuOptions";
import navigationLinks from "../../../resources/navigationLinks";
interface IProps {
	currentPage: string;
}

export const NavbarLinks: React.FC<IProps> = (props) => {
	const { currentPage } = props;
	const fontSizeBreakPoint = { base: "sm", xl: "xl" };

	return (
		<>
			<Box
				display="flex"
				ml="4"
				alignItems="center"
				d={{base: "none", lg: "flex"}}
			>
				{navigationLinks.map((navigation) => (
					<Flex
						key={navigation.name}
						flexDirection="column"
						marginRight={"6px"}
					>

						{navigation.link.href === currentPage && (
							<Box
								alignSelf="center"
								mr="5"
								h={1}
								w={"100%"}
								bg="secondary.500"
								position="relative"
								bottom={2}
							/>
						)}
						<LinkButton
							key={navigation.name}
							routeLink={navigation.link}
							color="primary.400"
						>
							<Text
								cursor="pointer"
								color="primary.500"
								fontSize={fontSizeBreakPoint}
								mr="5"
								bottom={3}
								fontWeight={navigation.link.href === currentPage ? "bold" : "normal"}
							>
								{navigation.name}
							</Text>
						</LinkButton>
					</Flex>
				))}
			</Box>
			<MenuOptions />
		</>
	);
};
