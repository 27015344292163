import React from "react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	Box,
	AccordionPanel,
	Heading,
	Divider,
} from "@chakra-ui/react";

interface IProps {
	items: Array<{
		title: string;
		description: string;
	}>;
}

export const FaqComponent: React.FC<IProps> = (props) => {
	const { items } = props;

	return (
		<Accordion allowToggle allowMultiple>
			{items.map((item, index) => (
				<AccordionItem
					key={index}
					p={2}
					my={2}
					backgroundColor="white"
					style={{ borderWidth: 0 }}
				>
					{({ isExpanded }) => (
						<Box
							borderWidth={1}
							borderColor="secondary.600"
							py={6}
							borderBottomRadius={10}
						>
							<AccordionButton>
								<Box flex="1" textAlign="left" pr={10}>
									<Heading fontSize="24px" color="primary.500">{item.title}</Heading>
								</Box>
								{isExpanded ? (
									<MinusIcon fontSize="24px" color="secondary.600" />
								) : (
									<AddIcon fontSize="24px" color="secondary.600" />
								)}
							</AccordionButton>
							<AccordionPanel fontSize="20px" color="gray.500">
								<Divider my={4} />
								{item.description}
							</AccordionPanel>
						</Box>
					)}
				</AccordionItem>
			))}
		</Accordion>
	);
};
